import { gql } from "@apollo/client";

export const getArtworkAutocompleteResultsQuery = gql`
  query getArtworkAutocompleteResults($query: String!) {
    getArtworkAutocompleteResults(query: $query) {
      extId
      src
      importRef {
        value
      }
      metadata {
        name
        creatorName
        assetFileFormat
      }
      matchedField {
        path
      }
    }
  }
`;

export const searchArtworksQuery = gql`
  query searchArtworks(
    $keyword: String
    $exactFilters: ArtworkQueryExactFilters
    $options: ArtworkQueryOptionsInput
  ) {
    searchArtworks(keyword: $keyword, exactFilters: $exactFilters, options: $options) {
      paginatedResults {
        extId
        src
        assetSrc
        assetDuration
        metadata {
          name
          creationDate
          creatorName
          copyrightOrRestrictions
          materialsAndTechnology
          seriesName
          relevantDates
          relevantGeos
          isPhysicalAsset
          assetFileFormat
          description
        }
        importRef {
          name
          value
        }
      }
      paginateMeta {
        totalCount
      }
    }
  }
`;

export const getSearchFacetsQuery = gql`
  query getSearchFacets($query: String!) {
    getSearchFacets(query: $query) {
      count {
        lowerBound
      }
      facet {
        orientationFacet {
          buckets {
            _id
            count
          }
        }
        relevantDatesFacet {
          buckets {
            _id
            count
          }
        }
        creatorNameFacet {
          buckets {
            _id
            count
          }
        }
        relevantGeosFacet {
          buckets {
            _id
            count
          }
        }
        materialsAndTechnologyFacet {
          buckets {
            _id
            count
          }
        }
        assetTypeFacet {
          buckets {
            _id
            count
          }
        }
        seriesNameFacet {
          buckets {
            _id
            count
          }
        }
        copyrightOrRestrictionsFacet {
          buckets {
            _id
            count
          }
        }
        exhibitionLoanHistoryFacet {
          buckets {
            _id
            count
          }
        }
        creationDateFacet {
          buckets {
            _id
            count
          }
        }
      }
    }
  }
`;
