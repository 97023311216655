import { useQuery } from "@apollo/client";
import { isEmpty } from "lodash";

import { QueryOptions } from "@/api/queries";
import { searchArtworksQuery } from "@/api/queries/search";
import { SearchArtworksQuery, SearchArtworksQueryVariables } from "@/gql/graphql";

const useSearch = ({
  keyword,
  exactFilters,
  options,
  skip = false,
}: {
  keyword: string;
  exactFilters?: any;
  options: QueryOptions;
  skip?: boolean;
}) => {
  const variables: any = {
    keyword,
    options,
  };
  if (!isEmpty(exactFilters)) {
    variables.exactFilters = exactFilters;
  }
  const res = useQuery<SearchArtworksQuery, SearchArtworksQueryVariables>(searchArtworksQuery, {
    variables: variables,
    fetchPolicy: "cache-and-network",
    skip,
  });

  return res;
};

export { useSearch };
