import { clsMerge } from "@artifactlabs/shared-react-utils";
import { Listbox } from "@headlessui/react";
import { FunctionComponent, HTMLAttributes, useState } from "react";
import { useRecoilState } from "recoil";

import ChevronArrowDown from "@/components/Icons/ChevronArrowDown";
import tenantAtom from "@/recoil/tenant/atom";

const options = [
  { value: "all", label: "All" },
  { value: "images", label: "Images" },
];

interface Prop extends HTMLAttributes<HTMLDivElement> {}

const ArtworkMediaTypeDropdown: FunctionComponent<Prop> = ({ ...rest }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const [tenant] = useRecoilState(tenantAtom);

  return (
    <div {...rest}>
      <Listbox value={selectedOption} onChange={setSelectedOption}>
        {/*  */}
        <Listbox.Button className="flex h-full w-full items-center focus:outline-none">
          <div className="flex h-full w-full items-center justify-between px-[16px] py-[12px]">
            <div className="flex-grow text-left">{selectedOption.label}</div>
            <ChevronArrowDown className="ml-auto h-[16px] w-[16px] fill-tenant-gray-dark stroke-[2px]" />
          </div>
        </Listbox.Button>

        {/*  */}
        <Listbox.Options
          className={clsMerge(
            "absolute mt-[24px] bg-white py-1 ring-1 focus:outline-none sm:text-sm",
            tenant.id === "scmp"
              ? "flex w-[123px] flex-col items-start rounded-[8px] ring-[#001246]"
              : "w-[144px] ring-black ",
          )}
        >
          {options.map(option => (
            <Listbox.Option className="w-full" key={option.label} value={option}>
              {({ active }) => (
                <div
                  className={`flex w-full items-center justify-center py-5 ${
                    active ? "bg-tenant-blue-light text-black" : " text-black"
                  }`}
                  role="button"
                >
                  {option.label}
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default ArtworkMediaTypeDropdown;
